<template>
  <div>
    <CCard>
      <CCardHeader>Edit Admin User</CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md='12' lg='6'>
            <CForm @submit='submitForm'>
              <CInput required placeholder='Site Name' v-model='nameInput.val' v-bind:class="{ valid: validateInput(nameInput.val, 'name') }" :invalidFeedback="invalidFeedback('name')" :isValid="validateInput(nameInput.val, 'name')">
                <template #prepend-content><CIcon name='cil-user'/></template>
              </CInput>
              <CInput v-model='domainInput.val' :readonly='true'>
                <template #prepend-content><CIcon name='cil-user'/></template>
              </CInput>
              <CInput v-model='codeInput.val' :readonly='true'>
                <template #prepend-content><CIcon name='cil-user'/></template>
              </CInput>
              <div class='form-group'>
                <CInputRadioGroup inline :options="[{ value: '0', label: 'Active'}, { value: '1', label: 'Inactive'}]" :checked.sync='statusInput.val'/>
              </div>
              <div class='form-group form-actions'>
                <CButton block type='submit' color='success' :disabled='formLoading'>
                  <span class='spinner-border spinner-border-sm' role='status' aria-hidden='true' v-if='formLoading'></span>
                  Update
                </CButton>
              </div>
              <div class='form-group form-actions'>
                <CLink @click='deleteItemModalShow()' :disabled='deleteLoading'>
                  <CIcon name='cil-trash' /> Delete this site <span class='spinner-border spinner-border-sm' role='status' aria-hidden='true' v-if='deleteLoading'></span>
                </CLink>
              </div>
            </CForm>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CModal title='Delete Site' color='danger' :centered='true' :show.sync='deleteModal' @update:show='deleteItemConfirm'>
      Are you sure you wish to delete this site '{{nameInput.val}}'?
    </CModal>
  </div>
</template>

<script>
import apiSite from '@/api/site'

export default {
  name: 'EditSite',
  components: {
  },
  data () {
    return {
      formLoading: false,
      deleteLoading: false,
      nameInput: { val: null, serverErrMsg: null , serverErrVal: null },
      domainInput: { val: null, serverErrMsg: null , serverErrVal: null },
      codeInput: { val: null, serverErrMsg: null , serverErrVal: null },
      statusInput: { val: null, serverErrMsg: null , serverErrVal: null },
      deleteModal: false
    }
  },
  created() {
    apiSite.get(this.$route.params.id).then(response => {
      this.nameInput.val = response.data.site.name
      this.domainInput.val = response.data.site.domain
      this.codeInput.val = response.data.site.code
      this.statusInput.val = ''+response.data.site.status
    }).catch((err) => {
      this.$toast.error('Error retrieving item.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
    })
  },
  methods: {
    validateInput (val, field) {
      if(val === null){
      }else{
        if(field == 'name'){ // alphanumeric with whitespace
          return (val && /^[a-zA-Z0-9\s]+$/.test(val) && val !== this.nameInput.serverErrVal) ? true : false
        }
        if(field == 'status'){ // 0 or 1
          return (val && (val == '0' || val == '1') && val !== this.statusInput.serverErrVal) ? true : false
        }
      }
    },
    invalidFeedback (field) {
      if(field == 'name'){
        return (this.nameInput.val !== this.nameInput.serverErrVal) ? 'Alphanumeric characters and spaces only' : this.nameInput.serverErrMsg
      }
      /*if(field == 'status'){
        return (this.statusInput.val !== this.statusInput.serverErrVal) ? 'Invalid status code' : this.usernameInput.serverErrMsg
      }*/
    },
    submitForm: function (e) {
      
      e.preventDefault();

      if(this.validateInput(this.nameInput.val, 'name')  && this.validateInput(this.statusInput.val, 'status')){
        
        this.formLoading = true

        apiSite.update(this.$route.params.id, this.nameInput.val, parseInt(this.statusInput.val)).then(response => {
          this.formLoading = false
          this.$toast.success('Successfully updated site.', { position: 'top-right', timeout: 3000, hideProgressBar: true })
          this.$router.go(-1) 
        }).catch((err) => {
          this.formLoading = false
          if(err.response.data.status == 'validation.error' && err.response.data.errors){
            err.response.data.errors.forEach((error) => {
              if(error.field == 'name'){
                this.nameInput.serverErrVal = this.nameInput.val
                this.nameInput.serverErrMsg = error.message
              }
            });
          }
          this.$toast.error('Failed to update site.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
        })

      }

    },
    deleteItemModalShow() {
      this.deleteModal = true
    },
    deleteItemConfirm(show, event, accepted) {
      if(accepted){
        this.deleteLoading = true
        apiSite.delete(this.$route.params.id).then(response => {
          this.deleteLoading = false
          this.$toast.success('Successfully deleted site.', { position: 'top-right', timeout: 3000, hideProgressBar: true })
          this.$router.go(-1) 
        }).catch((err) => {
          this.deleteLoading = false
          this.$toast.error('Failed to delete site.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
        })
      }
    },
  }
}
</script>
