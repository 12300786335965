import request from '@/api/axios-request'

const apiSite = {

  create(name, domain, code) {
    return request({
      url: '/ad/sites/create',
      method: 'post',
      data: {
        name,
        domain,
        code
      }
    })
  },

  get(id) {
    return request({
      url: '/ad/sites/get',
      method: 'post',
      data: {
        id
      }
    })
  },

  update(id, name, status) {
    return request({
      url: '/ad/sites/update',
      method: 'post',
      data: {
        id,
        name,
        status
      }
    })
  },

  delete(id) {
    return request({
      url: '/ad/sites/delete',
      method: 'post',
      data: {
        id
      }
    })
  },

  list(page, limit, sortColumn = null, sortOrder = null, filters = {}) {
    return request({
      url: '/ad/sites/list',
      method: 'get',
      params: {
        page: page,
        limit: limit,
        sortColumn: sortColumn,
        sortOrder: sortOrder,
        filters: encodeURI(JSON.stringify(filters))
      }
    })
  }

}

export default apiSite